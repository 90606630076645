var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sureSubmit"},[_c('div',{staticClass:"participants"},[_vm._m(0),_c('div',{staticClass:"body"},[_c('div',{staticClass:"newPeople"},[_vm._l((_vm.peopleList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{class:['item-t', { isSelect: item.isSelect }],on:{"click":function($event){return _vm.selectItem(item)}}},[_c('img',{staticClass:"avatar",attrs:{"src":item.applicantsPic
                  ? item.applicantsPic
                  : require('@/assets/img/default_avatar.png'),"alt":""}}),(index !== 0)?_c('div',{staticClass:"delete",on:{"click":function($event){$event.stopPropagation();return _vm.deleteRelation(item)}}},[_c('img',{attrs:{"src":require("./img/delete.png"),"alt":""}})]):_vm._e(),(item.isFamily == 1)?_c('div',{staticClass:"family"},[_vm._v("家人")]):_vm._e(),_vm._m(1,true)]),_c('div',{staticClass:"item-c",on:{"click":function($event){return _vm.toEdit(item, index)}}},[_c('div',{staticClass:"name"},[_c('span',[_vm._v(_vm._s(item.applicantsName))]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(index !== 0),expression:"index !== 0"}],attrs:{"src":require("./img/edit.png"),"alt":""}})]),_c('div',{staticClass:"tel"},[_vm._v(_vm._s(item.applicantsPhone))])]),_c('div',{staticClass:"item-b",on:{"click":function($event){$event.stopPropagation();return _vm.openRemarkDialog(item)}}},[_vm._m(2,true),_c('div',{staticClass:"item-br"},[_vm._v("备注")])])])}),(
            _vm.activityDetail.activityCategory != 1 &&
            _vm.activityDetail.activityCategory != 19 &&
            _vm.activityDetail.activityCategory != 20
          )?_c('div',{staticClass:"item te",on:{"click":_vm.toAdd}},[_vm._m(3),_vm._m(4)]):_vm._e()],2)])]),_c('div',{staticClass:"partDetail"},[_vm._m(5),_c('div',{staticClass:"body"},[_c('div',{staticClass:"actiName"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("活动名称")]),_c('div',{staticClass:"item-r"},[_vm._v(_vm._s(_vm.activityDetail.activityName))])])]),_c('div',{staticClass:"time"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("报名开始时间")]),_c('div',{staticClass:"item-r"},[_vm._v(" "+_vm._s(_vm.activityDetail.applyStartDate)+" ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("活动开始时间")]),_c('div',{staticClass:"item-r"},[_vm._v(" "+_vm._s(_vm.activityDetail.startDate)+" ")])])]),_c('div',{staticClass:"address"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-l"},[_vm._v("活动地点")]),_c('div',{staticClass:"item-r"},[_vm._v(" "+_vm._s(_vm.activityDetail.activityAddress)+" ")])])])])]),_c('div',{staticClass:"btnSubmit"},[_c('div',{staticClass:"submit",on:{"click":_vm.sureApply}},[_vm._v("确认提交")])]),_c('v-dialog',{attrs:{"title":"提示"},on:{"confirm":_vm.confirm},model:{value:(_vm.isDialog),callback:function ($$v) {_vm.isDialog=$$v},expression:"isDialog"}},[_c('div',{staticClass:"tips"},[_c('div',{staticClass:"tips-t"},[_vm._v("确定要删除这个参与人?")])])]),_c('v-dialog',{attrs:{"title":"提交备注"},on:{"confirm":_vm.confirmRemark},model:{value:(_vm.isRemarkDialog),callback:function ($$v) {_vm.isRemarkDialog=$$v},expression:"isRemarkDialog"}},[_c('div',{staticClass:"textarea"},[_c('v-input',{attrs:{"placeholder":"请填写备注","type":"textarea","maxlength":150,"showWordLimit":true},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"title-text"},[_vm._v("参与人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select"},[_c('img',{attrs:{"src":require("./img/user_select.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-bl"},[_c('img',{attrs:{"src":require("./img/edit_blue.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-t"},[_c('img',{attrs:{"src":require("./img/newAdd.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-c"},[_c('div',{staticClass:"name"},[_vm._v("添加成员")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"title-text"},[_vm._v("参与详情")])])
}]

export { render, staticRenderFns }